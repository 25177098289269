const baseUrl = import.meta.env.PUBLIC_URL;

if (!baseUrl) throw new Error('base url is not defined');

export const siteConfig = {
  name: 'Ganesh Mani',
  url: baseUrl,
  logo: '../../public/logo/logo.jpg',
  ogImage: '../../public/logo/logo.jpg',
  links: {
    twitter: 'https://twitter.com/ganeshmani009',
    github: 'https://github.com/ganeshmani',
    linkedin: 'https://www.linkedin.com/in/ganeshmani009/',
  },
};

export type SiteConfig = typeof siteConfig;

export const navigationItems = [
  {
    title: 'Blog',
    href: '/blog',
  },
  {
    title: 'About',
    href: '/about',
  },
  // {
  //   title: 'Tech Stack',
  //   href: '/tech-stack',
  // },
  // {
  //   title: 'Hardware',
  //   href: '/hardware',
  // },
  {
    title: 'Consulting Services',
    href: '/consulting-services',
  },
  {
    title: 'GitHub',
    href: siteConfig.links.github,
    external: true,
  },
  {
    title: 'Twitter',
    href: siteConfig.links.twitter,
    external: true,
  },
  {
    title: 'LinkedIn',
    href: siteConfig.links.linkedin,
    external: true,
  },
];

'use client';

import { navigationItems } from '@/config/site';
import { siteConfig } from '@/config/site';
import { cn } from '@/lib/utils';

export function MainNav({ pathname }: { pathname: string }) {
  return (
    <div className="mr-4 hidden md:flex">
      <a href="/" className="mr-6 flex items-center space-x-2">
        <img
          src={'/logo/banner.svg'}
          width={40}
          height={40}
          alt="Ganesh Mani"
        />
        <span className="hidden font-bold sm:inline-block">
          {siteConfig.name}
        </span>
      </a>
      <nav className="flex items-center space-x-6 text-sm font-medium">
        {navigationItems.map((item: any) => {
          if (item.external || !item.href) return null;

          return (
            <a
              key={item.title}
              href={item.href}
              className={cn(
                'transition-colors hover:text-foreground/80',
                pathname === item.href
                  ? 'text-foreground'
                  : 'text-foreground/60',
              )}
            >
              {item.title}
            </a>
          );
        })}
      </nav>
    </div>
  );
}

'use client';
import { useStore } from '@nanostores/react';
import { themeStore } from '@/stores/themeStore';
// import { useState, useEffect } from 'react';
import { MoonIcon, SunIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';

export function ModeToggle() {
  const theme = useStore(themeStore);

  const handleClick = () => {
    themeStore.set(theme === 'light' ? 'dark' : 'light');
  };

  // const [theme, setTheme] = useState(() => {
  //   if (typeof window !== 'undefined') {
  //     return localStorage.getItem('theme') ?? 'light'; // Initialize state based on localStorage or default to 'light'
  //   }
  //   return 'light'; // Default theme if window is not available
  // });

  // // Effect for applying the theme to the document and updating localStorage
  // useEffect(() => {
  //   if (theme) {
  //     document.documentElement.classList.toggle('dark', theme === 'dark');
  //     localStorage.setItem('theme', theme);
  //   }
  // }, [theme]); // Depend only on theme

  // const handleClick = () => {
  //   setTheme((currentTheme) => (currentTheme === 'light' ? 'dark' : 'light'));
  // };

  return (
    <Button variant="ghost" className="w-9 px-0" onClick={handleClick}>
      {theme === 'light' ? (
        <MoonIcon className=" h-[1.2rem] w-[1.2rem]" />
      ) : (
        <SunIcon className="h-[1.2rem] w-[1.2rem]" />
      )}
    </Button>
    // <DropdownMenu>
    //   <DropdownMenuTrigger asChild>
    //     <Button variant="ghost" className="w-9 px-0">
    //       <SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
    //       <MoonIcon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
    //       <span className="sr-only">Toggle theme</span>
    //     </Button>
    //   </DropdownMenuTrigger>
    //   <DropdownMenuContent align="end">
    //     <DropdownMenuItem onClick={handleClick}>Light</DropdownMenuItem>
    //     <DropdownMenuItem onClick={handleClick}>Dark</DropdownMenuItem>
    //   </DropdownMenuContent>
    // </DropdownMenu>
  );
}
